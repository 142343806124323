<template>
    <div>

        <div v-if="isPro">
            <div class="list-options">
                <input type="search" class="search" placeholder="Enter search term" v-model="searchString"/>
                <filters :filters="filters" @filter="onFilter"></filters>
            </div>

            <ul class="list" v-if="filteredItems.length > 0">
                <company
                        v-for="item in filteredItems"
                        :item="item"
                        :key="item.id"
                        @favorite="onFavorite"
                >
                </company>
            </ul>
            <div v-else>No results</div>
        </div>
        <div v-if="!isPro">
            You need to be pro to to view the IFFR Pro Connect.
        </div>

    </div>
</template>


<script>
    import _ from 'lodash';
    import Company from './company.vue';
    import Filters from './filters.vue';



    export default {
        data: () => {
            return {
                searchString: '',
                activeFilters: []
            };
        },


        props: ['items', 'filters', 'searchKeys', 'isPro'],


        computed: {
            // filter the list
            filteredItems: function() {
                return _.filter(this.items, (item) => {
                    // EXCLUDE companies with no guests
                    let hasGuests = true;
                    if(item && item.attendingGuests && item.attendingGuests.length === 0) hasGuests = false;

                    // text search
                    let inSearch = false;
                    _.forEach(this.searchKeys, (key) => {
                        if(_.includes(String(item[key]).toLowerCase(), this.searchString.toLowerCase())) inSearch = true;
                    });

                    // filters
                    let inFilters = true;
                    _.forEach(this.activeFilters, (filter) => {
                        if(filter.type === 'boolean' && filter.value && !item[filter.key]) inFilters = false;
                        if(filter.type === 'multiselect' && item[filter.key]  instanceof Array ) {
                            // property is an array
                            if(_.intersection(filter.value, item[filter.key]).length === 0 && filter.value.length > 0) inFilters = false;
                        } else {
                            // property not an array
                            if(filter.type !== 'boolean' && (!item[filter.key] || filter.value.indexOf(item[filter.key]) === -1) && (filter.value && filter.value.length > 0)) inFilters = false;
                        }
                    });

                    return (inSearch && inFilters && hasGuests);
                });
            }
        },



        methods: {
            onFilter(activeFilters) {
                this.activeFilters = activeFilters;
            },


            onFavorite(itemId) {
                this.items.forEach((i) => {
                    if(i.id === itemId) {
                        i.isFavorite = !i.isFavorite;
                    }
                });
            }
        },


        components: {
            Company,
            Filters
        }
    }
</script>


<style lang="scss">

</style>