<template>
    <div class="filter-item">

        <multiselect
                v-model="value"
                :options="options"
                :multiple="true"
                :hide-selected="true"
                :show-labels="false"
                :placeholder="placeholder"
                @input="onSelect"
        ></multiselect>


    </div>
</template>


<script>
    import Multiselect from 'vue-multiselect';
    // import 'vue-multiselect/dist/vue-multiselect.min.css'



    export default {


        data: () => {
            return {
                value: null,
                // options: []
            };
        },


        props: ['options', 'placeholder'],


        methods: {
            onSelect(event) {
                this.$emit('filter', event)
            }
        },


        components: {
            Multiselect
        }
    }
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">

</style>