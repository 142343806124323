<template>
    <div class="filters">

        <div class="filter-container" v-for="filter in filters" :key="filter.key">
            <filter-multiselect v-if="filter.type === 'multiselect'" :options="filter.options" :placeholder="filter.placeholder" @filter="onFilter(filter.key, filter.type, $event)"></filter-multiselect>
            <FilterBoolean v-if="filter.type === 'boolean'" :label="filter.name" @filter="onFilter(filter.key, filter.type, $event)"></FilterBoolean>
        </div>

    </div>
</template>


<script>
    import _ from 'lodash';
    import FilterMultiselect from './filter-multiselect.vue';
    import FilterBoolean from './filter-boolean.vue';




    export default {


        data: () => {
            return {
                activeFilters: []
            };
        },


        props: ['filters'],


        methods: {


            onFilter(key, type, value) {
                _.remove(this.activeFilters, (f) => f.key === key); // remove filter from active filter with the same key
                this.activeFilters.push({key, type, value}); // add filter to active filters
                this.$emit('filter', this.activeFilters);
            }
        },


        components: {
            FilterMultiselect,
            FilterBoolean
        }
    }
</script>


<style lang="scss">

</style>
