<template>
    <li class="item-wrapper">
        <div class="item">
            <div class="item-header">
                <h3><span>{{item.company}}</span></h3>
            </div>

            <div class="item-content">
                <ul class="company-details">
                    <li class="company-detail" v-if="isSet(item.country)">
                        <span class="company-detail__label">Country:</span>
                        <span class="company-detail__value">{{item.country}}</span>
                    </li>
                    <li class="company-detail" v-if="item.website">
                        <span class="company-detail__label">Website:</span>
                        <span class="company-detail__value word-break"><a target="_blank" :href="formatUrl(item.website)">{{item.website}}</a></span>
                    </li>
                    <li class="company-detail" v-if="item.email">
                        <span class="company-detail__label">Email:</span>
                        <span class="company-detail__value word-break"><a target="_blank" :href="'mailto:' + item.email">{{item.email}}</a></span>
                    </li>
                    <li class="company-detail" v-if="isSet(item.mainWorkingSector)">
                        <span class="company-detail__label">Main Working Sector(s):</span>
                        <span class="company-detail__value">{{item.mainWorkingSector}}</span>
                    </li>
                    <li class="company-detail" v-if="isSet(item.otherWorkingSector)">
                        <span class="company-detail__label">Other Working Sector(s):</span>
                        <span class="company-detail__value">{{item.otherWorkingSector}}</span>
                    </li>
                    <li class="company-detail" v-if="isSet(item.focus)">
                        <span class="company-detail__label">Focus:</span>
                        <span class="company-detail__value">{{join(item.focus)}}</span>
                    </li>
                    <li class="company-detail" v-if="isSet(item.territories)">
                        <span class="company-detail__label">Territories:</span>
                        <span class="company-detail__value">{{item.territories}}</span>
                    </li>
                </ul>

                <h4>Attending guest(s)</h4>
                <ul class="guests">
                    <li v-for="guest in item.attendingGuests" :key="guest.name">
                        <div class="guest-image">
                            <!--<img :src="guest.image" class="image">-->
                            <img :src="guestImage(guest.id)" class="image" onerror="this.src='http://www.knbs.or.ke/wp-content/uploads/2016/04/person-placeholder-300x275.jpg'">
                        </div>
                        <div class="guest-details">
                            <div class="name">{{guest.name}}</div>
                            <div v-if="guest.role" class="role">{{guest.role}}</div>
                            <div v-if="guest.email" class="email"><a :href="'mailto:'+guest.email">{{guest.email}}</a></div>
                            <div class="badges"><span v-for="badge in guest.badges" :key="badge" class="badge">{{badge}}</span></div>
                        </div>
                    </li>
                </ul>
            </div>

            <p @click="toggleCollapse(item)" class="toggle" v-if="showMore(item)">
                <span v-if="!item.collapse">Show more</span>
                <span v-if="item.collapse">Show less</span>
            </p>

            <transition name="collapse">
                <div v-if="item.collapse" class="additional-content">
                    <p v-if="isSet(item.companyProfile)">
                        <strong>Company Profile</strong><br>
                        {{item.companyProfile}}
                    </p>
                    <p v-if="isSet(item.objectives)">
                        <strong>Objectives</strong><br>
                        {{item.objectives}}
                    </p>
                </div>
            </transition>
        </div>
    </li>
</template>


<script>
    import _ from 'lodash';

    export default {

        props: ['item'],



        computed: {
            // // filter the list
            // fullName: function() {
            //     return this.item.firstName + ' ' + this.item.lastName;
            // },

            // formatted website address
            website: function() {
                return this.item.website.replace('http://', '').replace('https://', '');
            }
        },


        methods: {
            onFavorite: function(item) {
                // item.isFavorite = !item.isFavorite;
// console.log(item);
                // emit event to parent
                this.$emit('favorite', item.id)
            },


            showMore: function (item) {
                return (item.companyProfile || item.objectives)
            },


            isSet: function(value) {
                let set = false;
                if(value && value.length > 0) set = true;
                return set;
            },


            join: function(arr) {
                return _.join(arr, ', ');
            },



            toggleCollapse: function(item) {
                item.collapse = !item.collapse;
            },


            guestImage: function(guestId) {
                return 'https://proconnect.iffr.com/images/' + guestId + '.jpg';
            },


            formatUrl: function(url) {
                return url.includes('http://') || url.includes('https://') ? url : 'http://' + url;
            },


        }
    }
</script>


<style lang="scss">

</style>
