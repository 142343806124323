<template>
    <div>
        <!--<header>-->
            <!--<h1>IFFR Pro Connect</h1>-->
        <!--</header>-->
        <list :items='items' :filters='filters' :searchKeys="searchKeys" :is-pro="isPro"></list>

        <div @click="backToTop" class="back-to-top" :style="'top: ' + scrollY + 'px'">
            <a class="button">Search</a>
        </div>

    </div>
</template>


<script>
    import _ from 'lodash';
    import axios from 'axios';
    import List from './list.vue';
    // import auth from '../services/auth';

    export default {
        name: 'Main',

        components: {
            List
        },

        data () {
            return {
                filters: [
                    {
                        name: 'Working Sector(s)',
                        type: 'multiselect',
                        key: 'filterWorkingSectors',
                        placeholder: 'Filter on working sector',
                        options: []
                    },
                    {
                        name: 'Badges',
                        type: 'multiselect',
                        key: 'filterBadges',
                        placeholder: 'Filter on badge',
                        options: []
                    },
                    {
                        name: 'Type',
                        type: 'multiselect',
                        key: 'filterTypes',
                        placeholder: 'Filter on type',
                        options: []
                    },
                    // {
                    //     name: 'Main Working Sector(s)',
                    //     type: 'multiselect',
                    //     key: 'mainWorkingSector',
                    //     placeholder: 'Filter on working sector',
                    //     options: []
                    // },
                    // {
                    //     name: 'Other Working Sector(s)',
                    //     type: 'multiselect',
                    //     key: 'otherWorkingSector',
                    //     placeholder: 'Filter on other working sector',
                    //     options: []
                    // },
                    {
                        name: 'Country',
                        type: 'multiselect',
                        key: 'country',
                        placeholder: 'Filter on Country',
                        options: []
                    },
                    {
                      name: 'Territories',
                      type: 'multiselect',
                      key: 'territories',
                      placeholder: 'Filter on Territories',
                      options: []
                    },
                    // {
                    //     name: 'Only show favorites',
                    //     type: 'boolean',
                    //     key: 'isFavorite'
                    // }
                ],
                searchKeys: ['company', 'searchGuestName', 'searchGuestRole'], // with keys of the items should be searched in
                // guests: dataset
                items: [],
                isPro: false,

                scrollY: null
            }
        },

        methods: {

            backToTop: function() {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            },


            checkPro: async function() {
                const self = this;
                // this.isPro = await auth.authenticate();
this.isPro = true;

                if(this.isPro) {
                    axios.get('/proconnect.json')
                        .then(function (response) {
                            const dataset = response.data;

                            // set working sector filter options
                            const sectors = _.uniq(_.flatten(_.map(dataset.data, (g) => g.filterWorkingSectors)));
                            const sectorFilter = _.find(self.filters, {key: 'filterWorkingSectors'});
                            if(sectors && sectors[0]) sectorFilter.options = sectors.sort();

                            // set badge filter options
                            // const badges = _.uniq(_.flatten(_.map(dataset.data, (g) => g.filterBadges)));
                            const badgeFilter = _.find(self.filters, {key: 'filterBadges'});
                            // if(badges && badges[0]) badgeFilter.options = badges.sort();
                            // badgeFilter.options = ['CineMart', 'IFFR Pro - Industry', 'IFFR Pro - Cast & Crew', 'Jury', 'Rotterdam Lab'];
                            badgeFilter.options = ['CineMart', 'IFFR Pro - Industry', 'IFFR Pro - Director', 'IFFR Pro - Cast & Crew', 'Jury', 'Rotterdam Lab'];

                            // set guest type filter options
                            const guestTypeFilter = _.find(self.filters, {key: 'filterTypes'});
                            guestTypeFilter.options = ['CineMart: Project Team', 'CineMart: Immersive', 'CineMart: Rotterdam Lab', 'CineMart: BoostNL'];
                            // guestTypeFilter.options = ['CineMart: Project Team', 'CineMart: Immersive', 'CineMart: BoostNL'];

                            // // set mainWorkingSector filter options
                            // const sectors = _.uniq(_.flatten(_.map(dataset.data, (g) => g.mainWorkingSector)));
                            // const sectorFilter = _.find(self.filters, {key: 'mainWorkingSector'});
                            // if(sectors && sectors[0]) sectorFilter.options = sectors.sort();
                            //
                            // // set otherWorkingSector filter options
                            // const sectorsOther = _.uniq(_.flatten(_.map(dataset.data, (g) => g.otherWorkingSector)));
                            // const sectorOtherFilter = _.find(self.filters, {key: 'otherWorkingSector'});
                            // if(sectorsOther && sectorsOther[0]) sectorOtherFilter.options = sectorsOther.sort();

                            // set country filter options
                            const countries = _.uniq(_.map(dataset.data, (g) => g.country));
                            const countryFilter = _.find(self.filters, {key: 'country'});
                            if(countries) countryFilter.options = countries.sort();

                            // set territories filter options
                            const territories = _.uniq(_.flatten(_.map(dataset.data, (g) => g.filterTerritories)));
                            const territoriesFilter = _.find(self.filters, {key: 'territories'});
                            if(territories) territoriesFilter.options = territories.sort();

                            // add attributes to list items
                            self.items = _.map(dataset.data, (company) => {
                                company.isFavorite = false; // add favorite property
                                company.collapse = false; // add collapse property
                                return company;
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    // window.location.href = 'https://iffr.com';
                }
            }

        },

        mounted() {
            this.checkPro();

            // watch scroll position
            window.addEventListener('scroll', () => {
                this.scrollY = Math.round(window.scrollY);
            });

        },



    }

</script>


<style lang='scss'>

</style>
