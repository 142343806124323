<template>

    <div class="filter-item filter-boolean">
        <span>{{label}}</span>
        <toggle-button :value="false"
                       color="#ffbeff"
                       :sync="true"
                       :labels="false"
                       @change="onToggle"
        />
    </div>

</template>


<script>
    // import ToggleButton from 'vue-js-toggle-button'
    // import ToggleButton from 'vue-js-toggle-button/src/Button';

    export default {


        data: () => {
            return {
                value: null,
                // options: []
            };
        },


        props: ['label'],


        methods: {
            onToggle(event) {
                this.$emit('filter', event.value)
            }
        },


        components: {
            // ToggleButton
        }
    }
</script>



<style lang="scss">

</style>